.default-router-wrapper {
  width: 100%;
  height: 100%;
  opacity: 0;
  animation: fadeAndBounce 700ms ease 1s normal forwards;
}

@keyframes fadeAndBounce {
  0% {
    opacity: 0;
    transform: translateY(0)
  }

  50% {
    opacity: 0.5;
    transform: translateY(10px)
  }

  75% {
    opacity: 0.5;
    transform: translateY(-4px)
  }

  100% {
    opacity: 1;
    transform: translateY(0)
  }
}
